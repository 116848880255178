import React from 'react'
import Container from '@components/shared/container'
import Layout from '@components/layout/layout'

const LegalNotice = () => (
  <Layout>
    <Container>
      <div className="page-padding">
        <article className="simple-page">
          <div className="section section--big">
            <h2 className="section__title">Éditeur</h2>
            <div className="section__content">
              <p>Acolad Développements SASU</p>
              <p>775 rte des Savarines, 46090 Trespoux, France</p>
              <p>Société par actions simplifiée unipersonnelle au capital de 1000€</p>
              <p>SIREN: 807 777 917 - RCS Cahors</p>
              <p>Président: Raphaël Apard</p>
              <p>Téléphone : +33 (0)6 13 78 91 34</p>
              <p>Responsable publication: Raphaël Apard - <a href="raphael.apard@acolad.fr">raphael.apard@acolad.fr</a></p>
            </div>
            <h2 className="section__title">Hébergeur</h2>
            <div className="section__content">
              <p>OVH</p>
              <p>2 rue Kellermann  - 59100 Roubaix France</p>
            </div>
          </div>
          <div className="section section--small">
            <h2 className="section__title">Technologies Web utilisées</h2>
            <div className="section__content">
              <p>GatsbyJS<br />ReactJS</p>
            </div>
            <h2 className="section__title">Design</h2>
            <div className="section__content">
              <p>Sophie Quehen<br /><a href="http://sophiequehen.com/" target="_blank">http://sophiequehen.com/</a></p>
            </div>
          </div>
        </article>
      </div>
    </Container>
  </Layout>
)

export default LegalNotice
